export function getProcessEnv(): any | undefined {
  return process?.env;
}

export const isBrowser = (): boolean =>
  typeof window !== "undefined" && typeof window.document !== "undefined";

export const isServerSide = (): boolean => !isBrowser();

interface Env {
  TELIA_FE_ENVIRONMENT: "test" | "prod"; // SPA based
}

export function isTestEnv(): boolean {
  if (isServerSide()) {
    return getProcessEnv().TELIA_FE_ENVIRONMENT !== "prod"; // localhost is undefined - and resolves to test env...
  } else {
    const env: Env = JSON.parse(document.querySelector(`script[type="env"]`)?.innerHTML || "{}");
    return env.TELIA_FE_ENVIRONMENT !== "prod";
  }
}

export function isLocalServer(): boolean {
  if (isServerSide()) {
    // The environment variable is set in k8s.
    // If it is unset we are running "directly" on a local machine
    return !getProcessEnv().TELIA_FE_K8S;
  } else {
    return false;
  }
}
