import serviceWebs, { ServiceWeb } from "./serviceWebs";

export type ServiceWebsByTags = Record<string, ServiceWeb>;

export const getServiceWebs = (permissions?: string[]): ServiceWeb[] =>
  permissions ? serviceWebs().filter(byPermission(permissions)) : serviceWebs();

export const getServiceWebsByTags = (permissions?: string[]): ServiceWebsByTags =>
  allTags(permissions).reduce(byTags(permissions), {});

export const getServiceWebsByIBCategory = (
  category: string,
  permissions?: string[]
): ServiceWeb[] => getServiceWebs(permissions).filter(byCategory(category));

export const getServiceWebsInSRA = (): ServiceWeb[] => serviceWebs().filter(inSRA);

const byTags = (permissions?: string[]) => <T extends ServiceWebsByTags>(acc: T, tag: string) => {
  const filtered = getServiceWebs(permissions).filter(byTag(tag));
  return filtered.length ? { ...acc, [tag]: filtered } : acc;
};

const allTags = (permissions?: string[]): string[] => {
  const tags = getServiceWebs(permissions).flatMap((x) => x.tags);
  return [...new Set(tags)];
};

const byPermission = (permissions: string[]) => (x: ServiceWeb) =>
  permissions.includes(x.permission);

const byTag = (tag: string) => (x: ServiceWeb) => x.tags.includes(tag);

const byCategory = (category: string) => (x: ServiceWeb): boolean =>
  x.categories.includes(category);

const inSRA = (x: ServiceWeb): boolean => !["TELIA_FINANCE", "TELIA_ACE", "SD_WAN"].includes(x.id);
