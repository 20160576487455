import { pathToRegexp } from "path-to-regexp";

// TODO: replace path-to-regexp with STD regexp - easier and more reliable to test...

const scopeIdRegexp = pathToRegexp("/foretag/mybusiness/:scopeId(\\w+)(.*)");

export function getScopeFromUrl(url: string): string | null {
  const scopeIdCandidate = scopeIdRegexp.exec(url)?.[1] || null;

  return checkScopeId(scopeIdCandidate) ? scopeIdCandidate : null;
}

export function checkScopeId(scopeIdCandidate: string | null): boolean {
  return /^o?[A-Z0-9]+$/.test(scopeIdCandidate || "");
}
