import { addTranslations, translate as t } from "@telia/b2b-i18n";
import { stripDash } from "./utils";
import en from "./locales/en.json";
import sv from "./locales/sv.json";
import data from "./data/serviceWebs.json";
import { isTestEnv } from "@telia/b2b-utils";

export interface Data {
  categories: string[];
  id: string;
  permission: string;
  requiredAuthLevel: number;
  tags: string[];
  url: string;
  testUrl: string;
  openInNew?: boolean;
}

export interface ServiceWeb extends Data {
  description: string;
  title: string;
  messageTitle: string;
  message: string;
}

addTranslations({ en, sv });

const isRunningInTestEnv: boolean = isTestEnv();

export default (): ServiceWeb[] => data.map(mapDataAndTranslations);

const mapDataAndTranslations = (x: Data) => ({
  ...x,
  url: isRunningInTestEnv ? x.testUrl : x.url, // Note: if test env we will rewrite url and use the defined testUrl
  description: t(`serviceWebs.${stripDash(x.id)}.description`),
  title: t(`serviceWebs.${stripDash(x.id)}.title`),
  messageTitle: t(`serviceWebs.${stripDash(x.id)}.messageTitle`),
  message: t(`serviceWebs.${stripDash(x.id)}.message`),
});
